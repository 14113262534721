import {Badge} from "react-bootstrap";
import React from "react";
import Icon from '@mdi/react';
import {mdiCommentAlert} from "@mdi/js";

export default function CommentInfoTableCell({ id,className,children,rowData }) {

      return (
        <td key={id} className={`border px-4 py-2 text-nowrap ${className ? " " + className : ""}`}>
            {children} {rowData.has_comment ? <Icon color={"#ffcd29"} path={mdiCommentAlert} size={1} /> : null}
        </td>

      );
  }
