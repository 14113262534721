
import React from "react";
import {router, usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";
import {mdiFilterVariant, mdiPlus} from "@mdi/js";
import Icon from "@mdi/react";
import {Button} from "react-bootstrap";
import MailView from "./Email/MailView.jsx";
import {ToggleButton, usePolling} from "laravel-inertia-backend";

export default function Emails(props) {
    const { t } = useLaravelReactI18n();
    const { emails,extradata,filters,path } = usePage().props;
    const [mailViewId, setMailViewId] = React.useState(null);
    const filterNew = filters.filterNew === '1';


    const title = t(path === 'mail/mailorder' ? 'Mailorder' : 'Wholesale');
    const setFilterNew = (value) => {
        router.reload({data: {filter: {...filters, filterNew: value ? 1 : 0}}});
    }

    usePolling('emails');

  const handleAddClick = () => {
    router.get('mail/create');
  }

  const customToolbarButtons = (<ToggleButton className={"ms-2"} type="checkbox" id={"filter_new"} variant="outline-secondary"
                                              checked={!!filterNew} onChange={(e) => setFilterNew(e.currentTarget.checked)}
                                              value={"1"}><Icon path={mdiFilterVariant} size={1}/></ToggleButton>);

  const handleRowClick = (row) => {

      if (row) {
          setMailViewId(row.id);
          router.reload({only: ['extradata','emails'], data: {extradata: row.id}});
      } else {
          const url = new URL(window.location.href);
          // 'extradata'-Parameter entfernen
          url.searchParams.delete('extradata');
          // URL aktualisieren
          window.history.replaceState({}, document.title, url.toString());
      }

  }

  return (
      <>
      <InertiaTable data={ emails} title={title}
                    customRowStyle={(row) => {
                        if (row.status === 0) {
                            return {fontWeight: 'bolder'}
                        }
                    }}
                    customToolbarButtons={customToolbarButtons} onRowClick={handleRowClick}/>
          {mailViewId && <MailView mail={extradata}
                                    onClose={() => setMailViewId(null)}/>}
      </>
  );
}



