import React from 'react';
import {Card, Table, Container} from 'react-bootstrap';

const KeyValueTable = ({title, keyHeader, valueHeader, button = null, data, valueRenderer = null}) => {

    return (
        <Card className="mb-4">
            <Card.Header className={"d-flex justify-content-between"} as="h5">{title}
                {button && button}
            </Card.Header>
            <Card.Body>
                {(data) &&
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <th>{keyHeader}</th>
                        <th>{valueHeader}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(data).map(([key, value]) => (
                        <tr key={key}>
                            <td>{key}</td>
                            <td>
                                {valueRenderer ? valueRenderer(value) : value}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>}
            </Card.Body>
        </Card>
    );
};

export default KeyValueTable;
