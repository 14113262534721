import Dropdown from 'react-bootstrap/Dropdown';
import Badge from 'react-bootstrap/Badge';
import React, {useState} from 'react';

export default function PillTableCell({id, className, children, selectList, onSelect, rowData}) {
    const [selectMode, setSelectMode] = useState(false);

    // Filtere nur Inhalte, die sinnvoll sind (nicht leer, keine Leerzeichen, kein null/undefined)
    const validChildren = React.Children.toArray(children).filter(
        (child) => typeof child === "string" && child.trim() !== "" // prüft auf Strings ohne Leerzeichen
    );

    const onSelectClick = (e) => {
        e.preventDefault();
        console.log("onSelectClick");
        setSelectMode(true);
    };

    const UserPillElement = React.forwardRef(({user, onClick}, ref) => {
        if (user) {
            return (
                <Badge ref={ref} pill bg={"none"} style={{backgroundColor: user.color ?? 'grey'}}
                       onClick={(e) => {
                           e.preventDefault();
                           onClick(e);
                       }}>
                    {user.preview_image && <img className={'pill-user-image'} src={user.preview_image} alt="Preview"/>}
                    {user.name}
                </Badge>);
        }
        return (<Badge ref={ref} onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
                pill
                bg={"none"}
                text={"dark"}
                style={{border: "1px dashed grey"}}
            >
                +
            </Badge>
        );
        }
    );

    return (
        <td key={id} className={`border px-4 py-2${className ? " " + className : ""}`}>
            {selectList && Object.entries(selectList).length > 0 && (
                <Dropdown onSelect={(value) => {
                    onSelect(value);
                    setSelectMode(false);
                }}>
                    <Dropdown.Toggle as={UserPillElement} user={selectList[rowData.assigned_user_id]}
                                     id="dropdown-select-user">
                        Custom toggle
                    </Dropdown.Toggle>
                    <Dropdown.Menu>

                        {rowData.assigned_user_id &&
                            <Dropdown.Item eventKey={null} key={null}>
                                <Badge  pill
                                                            bg={"none"}
                                                            text={"dark"}
                                                            style={{border: "1px dashed grey"}}
                        >
                            -
                        </Badge>
                        </Dropdown.Item>}
                        {Object.entries(selectList).map(([key, value]) => {
                                return (
                                    <Dropdown.Item eventKey={key} key={key}>
                                        <Badge pill bg={"none"} style={{backgroundColor: value.color ?? 'grey'}}>
                                            {value.preview_image &&
                                                <img className={'pill-user-image'} src={value.preview_image}
                                                     alt="Preview"/>}
                                            {value.name}
                                        </Badge>
                                    </Dropdown.Item>
                                )
                            }
                        )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </td>
    );

}
