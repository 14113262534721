// resources/js/Pages/CronResult.jsx

import React from 'react';
import { Head } from '@inertiajs/react';
import {Card, Container} from "react-bootstrap";
import {useLaravelReactI18n} from "laravel-react-i18n";

export default function CronResult({ message,cronOutput }) {
    const { t,getLocales } = useLaravelReactI18n();

    return (
        <Container className="mt-3">
            <Card>
                <Card.Header>
                    <h5>{t('Cronjob Status')}</h5>
                </Card.Header>
                <Card.Body>
                    <p>{message}</p>
                    <pre>
                       {cronOutput}
                    </pre>
                </Card.Body>
            </Card>
        </Container>
    );
}
