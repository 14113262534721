import {
    mdiViewDashboardVariant,
    mdiCalendarCheck,
    mdiAccount,
    mdiInvoiceImport,
    mdiInvoiceExportOutline,
    mdiSpray,
    mdiInvoiceListOutline,
    mdiIncognito,
    mdiEmailMultipleOutline, mdiTwoFactorAuthentication
} from "@mdi/js";

const icons = {
    mdiViewDashboardVariant,
    mdiCalendarCheck,
    mdiAccount,
    mdiInvoiceImport,
    mdiInvoiceExportOutline,
    mdiInvoiceListOutline,
    mdiSpray,
    mdiIncognito,
    mdiEmailMultipleOutline,
    mdiTwoFactorAuthentication
};

export default icons;
