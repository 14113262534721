import {useForm} from "@inertiajs/react";
import React from "react";
import {Row, Col, Card, Form, Button} from 'react-bootstrap';
import {useLaravelReactI18n} from "laravel-react-i18n";

export default function Login(props) {
    const { t,getLocales } = useLaravelReactI18n();
    const {data, setData, post, errors} = useForm({
        login: "",
        password: "",
        remember: false,
    });

    const submitLogin = (e) => {
        e.preventDefault();
        post("/login");
    }

    return (
        <div style={{
            minHeight: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(/images/background-login.jpg)`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
        }}>
            <Row className="d-flex justify-content-center" style={{width:'100%'}}>
                <Col md={6} style={{maxWidth: '800px'}}>
                    <h2><img src="/images/logo.svg" alt="Logo" style={{ height: '1.5em',paddingLeft: '5px' }} /></h2>
                    <Card className="px-5 py-5" id="form1" style={{boxShadow: "darkgrey 13px 13px 25px -2px"}}>
                        <Card.Body>
                            {
                                <div className="form-data">
                                    <Form onSubmit={submitLogin}>
                                        <Form.Group className="mb-4">
                                            <Form.Label>{t('Email or username')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                autoComplete="off"
                                                value={data.login} onChange={e => setData('login', e.target.value)}
                                                isInvalid={!!errors.login}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                A valid email is required!
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label>{t('Password')}</Form.Label>
                                            <Form.Control
                                                type="password"
                                                autoComplete="off"
                                                onChange={e => setData('password', e.target.value)}
                                                isInvalid={!!errors.password}
                                            />
                                            <Form.Control.Feedback type='invalid'>
                                                {errors.password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                          <Form.Check type="checkbox" label={t('Remember me')} checked={data.remember} onChange={e => setData('remember', e.target.value)}/>
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Button type="submit" variant="dark" block>{t('Login')}</Button>
                                        </Form.Group>
                                    </Form>
                                </div>
                            }
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
