import React, {useEffect, useState} from 'react';
import {router, usePage, usePoll} from '@inertiajs/react';
import {Button} from 'react-bootstrap';
import {usePolling} from 'laravel-inertia-backend';

const OAuthPage = () => {
    const { state } = usePage().props; // Zugriff auf den Server-Status

    const [oauthStarted,setOauthStarted] = useState(false);
    const [oauthUrl,setOauthUrl] = useState("");
    // OAuth-Login-Funktion
    const handleOAuthLogin = (provider) => {
        window.open(`/oauth/start/${provider}`, '_blank');
        setOauthStarted(provider);
    };

    usePolling(['state']); // Registriere state für Polling

    const handleManualRedirectUrl = (url) => {

        const params = new URL(url).search;
        console.log(url);
        router.get(`/oauth/${oauthStarted}/return${params}`);
    }

    // Überprüfung des Gesamtstatus
    const isWholesaleOk = state["1"]; // Zustand für Wholesale
    const isMailorderOk = state["2"]; // Zustand für Mailorder
    const isAllOk = isWholesaleOk && isMailorderOk; // Beide müssen true sein

    return (
        <div className="container-fluid mt-3">
            <h2>OAuth Login</h2>

            {isAllOk ? ( // Status ist okay, wenn beide true sind
                <div>
                    <h4>Status:</h4>
                    <p className="text-success fw-bold">Alles ist in Ordnung!</p>
                </div>
            ) : ( // OAuth-Aufforderung anzeigen
                <div>
                    <h4>Status:</h4>
                    {!isWholesaleOk && (
                        <p className="text-danger fw-bold">Wholesale Verbindung funktioniert nicht, Bitte starten Sie
                            die OAuth-Autorisierung:</p>)}
                    {!isMailorderOk && (
                        <p className="text-danger fw-bold">Mailorder Verbindung funktioniert nicht, Bitte starten Sie
                            die OAuth-Autorisierung:</p>)}
                    <div className="d-flex gap-3">
                        {!isWholesaleOk && ( // Button für Wholesale anzeigen, falls "1" false ist
                            <Button
                                variant="primary"
                                onClick={() => handleOAuthLogin(1)}
                            >
                                Starten Sie OAuth für Wholesale
                            </Button>
                        )}
                        {!isMailorderOk && ( // Button für Mailorder anzeigen, falls "2" false ist
                            <Button
                                variant="info"
                                onClick={() => handleOAuthLogin(2)}
                            >
                                Starten Sie OAuth für Mailorder
                            </Button>
                        )}
                    </div>
                    {oauthStarted && (

                        <div className="input-group mt-4">
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Geben Sie die redirect URL ein"
                                value={oauthUrl}
                                onChange={(e) => setOauthUrl(e.target.value)}
                            />
                            <Button
                                variant="success"
                                onClick={() => {handleManualRedirectUrl(oauthUrl)}}
                            >
                                Senden
                            </Button>
                        </div>
                    )}
                </div>

            )}
        </div>
    );
};

export default OAuthPage;
