import React from "react";
import {Badge} from "react-bootstrap";

const ShippingInfo = ({shippingInfo}) => {

    if (!shippingInfo) {
        return null; // Falls keine Versanddaten vorhanden sind
    }

    const currency = (value) => {
        return new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(value);
    }
    return (
        <div className="molotow-shipping cart-totals-wrapper">
            <div className="cart-totals">
                {shippingInfo.bulky && shippingInfo.bulky.length > 0 && (
                    <div className="bulky molotow-shipping-method">
                        <label>
                            bulky <span className="packet-count">{shippingInfo.bulky.length}</span>
                        </label>
                        <table id="shopping-cart-totals-table">
                            <colgroup>
                                <col/>
                                <col width="1"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td>
                                    <div className="paket">
                                        <img
                                            className="packet-img"
                                            src={"/images/bulky_small.png"}
                                            alt="bulky shipment"
                                        />
                                    </div>
                                </td>
                                <td className="a-right">
                    <span className="price">
                      <div className="price">{currency(shippingInfo.price.bulky)}</div>
                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {shippingInfo.dangerous && shippingInfo.dangerous.length > 0 && (
                    <div className="dangerous molotow-shipping-method">
                        <label>
                            Gefahrgut: <Badge pill bg={"danger"}>{shippingInfo.dangerous.length}</Badge>
                        </label>
                        <table id="shopping-cart-totals-table">
                            <colgroup>
                                <col/>
                                <col width="1"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td>
                                    {shippingInfo.dangerous.map((pack, index) => (
                                        <div className="paket" key={index}>
                                            <img
                                                className="packet-img"
                                                src={"/images/gefahrgut_small.png"}
                                                alt="dangerous shipment"
                                            />
                                        </div>
                                    ))}
                                </td>
                                <td className="a-right">
                    <span className="price">
                      <div className="price">{currency(shippingInfo.price.dangerous)}</div>
                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}

                {shippingInfo.standard && shippingInfo.standard.length > 0 && (
                    <div className="standard molotow-shipping-method">
                        <label>
                            Standard-Pakete <Badge pill bg={"danger"}>{shippingInfo.standard.length}</Badge>
                        </label>
                        <table id="shopping-cart-totals-table">
                            <colgroup>
                                <col/>
                                <col width="1"/>
                            </colgroup>
                            <tbody>
                            <tr>
                                <td>
                                    {shippingInfo.standard.map((pack, index) => (
                                        <div className="paket" key={index}>
                                            <img
                                                className="packet-img"
                                                src={"/images/paket_small.png"}
                                                alt="standard shipment"
                                            />
                                        </div>
                                    ))}
                                </td>
                                <td className="a-right">
                    <span className="price">
                      <div className="price">{currency(shippingInfo.price.standard)}</div>
                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ShippingInfo;
