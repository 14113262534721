import React, {useState} from "react";
import {router, usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";
import PillTableCell from "../Components/PillTableCell.jsx";
import CurrencyTableCell from "../Components/CurrencyTableCell.jsx";
import StatusTableCell from "../Components/StatusTableCell.jsx";
import {Col, Container, Form, Modal, Row, ToggleButtonGroup} from "react-bootstrap";
import {Button, ToggleButton, usePolling} from "laravel-inertia-backend";
import {mdiAccount, mdiCog, mdiFilterVariant, mdiLoading} from "@mdi/js";
import Icon from "@mdi/react";
import ChildView from "./Orders/ChildView.jsx";
import CommentInfoTableCell from "../Components/CommentInfoTableCell.jsx";


export default function Orders(props) {
    const {t, getLocales} = useLaravelReactI18n();
    const {orders, extradata, col_definitions, visibleCols, userList, filters} = usePage().props;
    const [showModal, setShowModal] = useState(false);
    const [columns, setColumns] = useState(col_definitions.map((colDef) => {
            if (colDef.hidden) {
                return {hidden:true, id:colDef.id}
            }
            return {hidden:false, id:colDef.id}
        }
    ));

    console.log(columns);
    const {filterShop} = filters;
    const filterAssigned = filters.filterAssigned === '1';
    const filterNew = filters.filterNew === '1';

    usePolling('orders'); // Polling für Bestellungen aktivieren

    const handleRowClick = (order) => {
        // Implement your event handling code here
        router.get('order/show', {id: order.id});
    }

    const setFilterShop = (value) => {
        // Andernfalls die neue Auswahl setzen
        router.reload({data: {filter: {...filters, filterShop: value}}});

    }

    const setFilterAssigned = (value) => {
        router.reload({data: {filter: {...filters, filterAssigned: value ? 1 : 0}}});
    }

    const setFilterNew = (value) => {
        router.reload({data: {filter: {...filters, filterNew: value ? 1 : 0}}});
    }

    const handleChildRowContentRequest = (id) => {
        if (id !== -1) {
            router.reload({only: ['extradata', 'orders'], data: {extradata: id}});
        } else {
            const url = new URL(window.location.href);
            // 'extradata'-Parameter entfernen
            url.searchParams.delete('extradata');
            // URL aktualisieren
            window.history.replaceState({}, document.title, url.toString());
        }
    }

    const childLoadingPlaceholder = (
        <div className="spinner-container"
             style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px'}}>
            <Icon path={mdiLoading} size={2} spin={1}/>
        </div>
    );

    const onAssignedUserChange = (id, value) => {
        console.log("assigned user change");
        console.log(id);
        console.log(value);
        axios.get(`order/assign?id=${id}&assigned_user=${value}`).then(response => {
            router.reload();
        })

    }

    const handleColumnVisibilityChange = (id) => {
        setColumns(
            columns.map((col) =>
                col.id === id ? {...col, hidden: !col.hidden} : col
            )
        );
    };

    const applyColumnChanges = () => {
        const filteredColumns = columns.filter(column => column !== 'title');

        // Hier könntest du die neuen Einstellungen an den Server übergeben, falls nötig
        console.log('Gefilterte Spalten-Einstellungen:', filteredColumns);

        router.reload({data: {setVisibleCols: JSON.stringify(filteredColumns)}});


        setShowModal(false);
    };

    const childRowContent = (<ChildView order={extradata}></ChildView>);

    const customToolbarButtons = (
        <>
            <Button tooltip={t('Sichtbare Spalten einstellen')}
                    className={'ms-2'}
                    variant="secondary"
                    onClick={() => setShowModal(true)}
            >
                <Icon path={mdiCog} size={1}/>
            </Button>
            <ToggleButtonGroup className={'ms-2'} name="sortorder_switch" value={filterShop} type="radio"
                               onChange={setFilterShop}>
                <ToggleButton id={"filter_b2c"} variant="outline-secondary" value={"b2c"}>{t('B2C')}</ToggleButton>
                <ToggleButton id={"filter_all"} variant="outline-secondary" value={"all"}>{t('alle')}</ToggleButton>
                <ToggleButton id={"filter_b2b"} variant="outline-secondary" value={"b2b"}>{t('B2B')}</ToggleButton>
            </ToggleButtonGroup>
            <ToggleButton className={"ms-2"} type="checkbox" id={"filter_assigned"} tooltip={t('nur mir zugewiesen')} variant="outline-secondary"
                          checked={!!filterAssigned} onChange={(e) => setFilterAssigned(e.currentTarget.checked)}
                          value={"1"}><Icon path={mdiAccount} size={1}/></ToggleButton>
            <ToggleButton className={"ms-2"} type="checkbox" id={"filter_new"} tooltip={t('nur neue')} variant="outline-secondary"
                          checked={!!filterNew} onChange={(e) => setFilterNew(e.currentTarget.checked)}
                          value={"1"}><Icon path={mdiFilterVariant} size={1}/></ToggleButton>
        </>
    );

    const getColVisiblitiy = (id) => {
        const column = columns.find(col => col.id === id);
        return column ? column.hidden : false;
    };

    return (<>
            <InertiaTable
                hiddenCols={columns.filter(col => col.hidden).map(col => col.id)}
                customRowStyle={(row) => {
                    if (row.assigned_user_id === null) {
                        return {fontWeight: 'bolder'}
                    }
                }}
                customCellRendererMap={{
                    'shop_order_id': CommentInfoTableCell,
                    'AssignedUser.name':
                        ({id, className, children, rowData}) => (
                            <PillTableCell id={id} rowData={rowData} className={className} children={children}
                                           selectList={userList}
                                           onSelect={(selectedValue) => onAssignedUserChange(rowData.id, selectedValue)}/>),
                    'total': CurrencyTableCell,
                    'total_incl_tax': CurrencyTableCell
                }}
                data={orders} options={{striped: true, childRows: true}} title={t('Orders')}
                childRowContent={childRowContent}
                customToolbarButtons={customToolbarButtons}
                onChildRowContent={handleChildRowContentRequest}
                childLoadingPlaceholder={childLoadingPlaceholder}
            />

            {/* Modal zum Einstellen der sichtbaren Spalten */}
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Sichtbare Spalten einstellen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="ms-2">
                        {col_definitions.map((colDef) => {
                                if (colDef.hidden) {
                                    return null;
                                }
                                return (<Form.Check
                                        type="switch"
                                        id={`column-${colDef.id}`}
                                        key={colDef.id}
                                        label={t(colDef.title)}
                                        checked={!getColVisiblitiy(colDef.id)}
                                        onChange={() => handleColumnVisibilityChange(colDef.id)}
                                    />
                                )
                            }
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Abbrechen
                    </Button>
                    <Button variant="primary" onClick={applyColumnChanges}>
                        Übernehmen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
