import React, {useState} from "react";
import {Container, Form, Card, Button, Col, Row, Modal} from "react-bootstrap";
import {useLaravelReactI18n} from "laravel-react-i18n";
import {router, usePage} from "@inertiajs/react";
import {useForm} from "@inertiajs/react";
import {ReactSelectBootstrap} from "react-select-bootstrap";
import KeyValueTable from "../../Components/KeyValueTable.jsx";
import {allExpanded, defaultStyles, JsonView} from "react-json-view-lite";


function Edit() {
    const {t} = useLaravelReactI18n();
    const {user, roles, settings,users} = usePage().props;
    const [imagePreview, setImagePreview] = useState(null);
    const [showCopySettingsModal, setShowCopySettingsModal] = useState(false);
    const [selectedUserForCopy, setSelectedUserForCopy] = useState(null);

    const jsonStyle = {
        ...defaultStyles,
        container: 'json-view-container'
    };

    // Konvertiere die Rollen in das Format für react-select-bootstrap
    const roleOptions = roles.map(role => ({
        value: role.id, // ID der Rolle
        label: role.name, // Name der Rolle
    }));

    const userOptions = Object.keys(users).map(id => ({
        value: parseInt(id), // Schlüssel (id) wird zur value, als Zahl.
        label: users[id]     // Wert aus dem Objekt wird zur label.
    }));

    // Initialisiere die bestehenden Benutzerrollen
    const initialUserRoles = user && user.roles
        ? user.roles.map(role => ({
            value: role.id, // ID aus der Nutzer-Rollen-Struktur
            label: roles.find(r => r.id === role.id)?.name || `Role ${role.id}`, // Name aus den globalen Rollen
        }))
        : [];

    const {data, setData, post, errors} = useForm(
        user
            ? {
                name: user.name,
                email: user.email,
                role_id: initialUserRoles.map(role => role.value),
                password: "",
                color: user.color || "#000000", // Default color if not set
                image: user.preview_image,
                homepage_route: user.homepage_route,
            }
            : {
                name: "",
                email: "",
                role_id: 0,
                password: "",
                color: "#000000", // Default color
                image: null,
                homepage_route:null,
            }
    );

    function handleRoleChange(selectedOptions) {
        const selectedRoleIds = selectedOptions.map(option => option.value); // IDs der gewählten Rollen
        setData({...data, role_id: selectedRoleIds});
    }

    function handleChange(e) {
        const key = e.target.id;
        const value = e.target.type === "file" ? e.target.files[0] : e.target.value; // Handle file input
        setData({
            ...data,
            [key]: value,
        });
    }

    function handleSubmit(e) {
        e.preventDefault();
        const submitData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            submitData.append(key, value);
        });

        if (user) {
            post(`/user/${user.id}`, {data: submitData, forceFormData: true});
        } else {
            post(`/user`, {data: submitData, forceFormData: true});
        }
    }

    const handleCopySettings = () => {
        router.get('/user/copysettings/' + selectedUserForCopy.value + '/'+user.id);
    }

    return (<>
            <Container className={'mt-3'}>
                <Form onSubmit={handleSubmit}>
                    <Card className={"mb-3"}>
                        <Card.Header className={"d-flex justify-content-between"}>
                            <h5>{t(user ? "Edit User" : "Create User")}</h5>
                            <Button variant="primary" type="submit">
                                {t("save")}
                            </Button>
                        </Card.Header>
                        <Card.Body>
                            {/* Name Field */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Name
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        className={errors.name ? "is-invalid" : ""}
                                        placeholder="Enter name"
                                        id="name"
                                        type="text"
                                        value={data.name}
                                        onChange={handleChange}
                                    />
                                    {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                </Col>
                            </Form.Group>

                            {/* Email Field */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Email
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        className={errors.email ? "is-invalid" : ""}
                                        placeholder="Enter email"
                                        id="email"
                                        type="email"
                                        value={data.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Usergroup
                                </Form.Label>
                                <Col sm={10}>
                                    {/* react-select-bootstrap für die multi-Auswahl */}
                                    <ReactSelectBootstrap
                                        isMulti
                                        options={roleOptions} // Globale Rollenliste
                                        value={roleOptions.filter(option => data.role_id.includes(option.value))} // Vorauswahl
                                        onChange={handleRoleChange} // Handler für Änderungen
                                        className={errors.role_id ? 'is-invalid' : ''}
                                    />
                                    {errors.role_id && <div className="invalid-feedback">{errors.role_id}</div>}
                                </Col>
                            </Form.Group>

                            {/* Password Field */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Password
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        className={errors.password ? "is-invalid" : ""}
                                        placeholder="Enter password"
                                        id="password"
                                        type="password"
                                        value={data.password}
                                        onChange={handleChange}
                                    />
                                    {errors.password && <div className="invalid-feedback">{errors.password}</div>}
                                </Col>
                            </Form.Group>

                            {/* Color Picker Field */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Color
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        className={errors.color ? "is-invalid" : ""}
                                        id="color"
                                        type="color"
                                        value={data.color}
                                        onChange={handleChange}
                                    />
                                    {errors.color && <div className="invalid-feedback">{errors.color}</div>}
                                </Col>
                            </Form.Group>


                            {/* Existing Image */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    {t('image')}
                                </Form.Label>
                                <Col sm={10}>
                                    {data.image && !(data.image instanceof File) ? (
                                            <img src={data.image} alt="" width="30"/>) :
                                        <img src={imagePreview} alt="" width="30"/>}
                                </Col>
                            </Form.Group>

                            {/* Image Upload Field */}
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Image
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        className={errors.image ? "is-invalid" : ""}
                                        id="image"
                                        type="file"
                                        accept="image/*" // Accept only images
                                        onChange={handleChange}
                                    />
                                    {errors.image && <div className="invalid-feedback">{errors.image}</div>}
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    {t('Homepage')}
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control
                                        as="select"
                                        id="homepage_route"
                                        value={data.homepage_route || ""}
                                        onChange={handleChange}
                                        className={errors.homepage_route ? "is-invalid" : ""}
                                    >
                                        <option value="" disabled>{t("Select a homepage")}</option>
                                        <option value="dashboard">{t('Dashboard')}</option>
                                        <option value="product">{t('Products')}</option>
                                        <option value="order">{t('Orders')}</option>
                                        <option value="mailorder">{t('Mailorder')}</option>
                                        <option value="wholesale">{t('Wholesale')}</option>
                                    </Form.Control>
                                    {errors.homepage_route && <div className="invalid-feedback">{errors.homepage_route}</div>}
                                </Col>
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Form>
                <KeyValueTable title={t('user settings')} button={<Button variant="primary" onClick={() => setShowCopySettingsModal(true)} type="submit">
                    {t("copy usersettings")}
                </Button>} keyHeader={"Schlüssel"} valueHeader={"Wert"} data={settings}
                               valueRenderer={(value) => <JsonView style={jsonStyle} data={value}
                                                                   shouldExpandNode={allExpanded}/>}></KeyValueTable>
            </Container>
            <Modal show={showCopySettingsModal} onHide={() => setShowCopySettingsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Select User to Copy Settings")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ReactSelectBootstrap
                        options={userOptions} // Options for users
                        value={selectedUserForCopy} // Currently selected user
                        onChange={(selected) => setSelectedUserForCopy(selected)} // Update selected user
                        className={errors.user_copy ? 'is-invalid' : ''}
                    />
                    {errors.user_copy && <div className="invalid-feedback">{errors.user_copy}</div>}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowCopySettingsModal(false)}>
                        {t("Cancel")}
                    </Button>
                    <Button
                        variant="primary"
                        onClick={() => {
                            handleCopySettings();
                            setShowCopySettingsModal(false);
                        }}
                    >
                        {t("Copy Settings")}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>

    );
}

export default Edit;
