import React, { useState } from 'react';
import {router, usePage} from "@inertiajs/react";
import {Button, Form, Table, Modal, Container, InputGroup} from 'react-bootstrap';
import {useLaravelReactI18n} from "laravel-react-i18n";

const Roles = ({ roles, permissions }) => {
    const { t } = useLaravelReactI18n();
    const [roleName, setRoleName] = useState('');
    const [selectedRole, setSelectedRole] = useState(null);

    const handleCreateRole = (e) => {
        e.preventDefault();
        router.post('/roles', { name: roleName });
    };

    const handleDeleteRole = (roleId) => {
        if (confirm('Möchten Sie diese Rolle wirklich löschen?')) {
            router.delete(`/roles/${roleId}`);
        }
    };

    const handleSyncPermissions = (roleId, permissionId) => {
        const selectedPermissions = roles.find(r => r.id === roleId).permissions.map(p => p.id);
        const updatedPermissions = selectedPermissions.includes(permissionId)
            ? selectedPermissions.filter(id => id !== permissionId)
            : [...selectedPermissions, permissionId];

        router.post(`/roles/${roleId}/permissions`, { permissions: updatedPermissions });
    };

    return (
        <Container fluid>
            <div className="mt-1 pt-1 pb-3 d-flex">
                <h1>{t('Roles')}</h1>
            </div>

            <Form onSubmit={handleCreateRole} className="my-4">
                <Form.Group className="w-25" controlId="formBasicEmail">
                    <Form.Label>Neue Rolle erstellen</Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="text"
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                            placeholder="Name der Rolle"
                            required
                        />
                        <Button type="submit" variant="primary">Rolle erstellen</Button>
                    </InputGroup>
                </Form.Group>
            </Form>

            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Rolle</th>
                    <th>Berechtigungen</th>
                    <th>Aktionen</th>
                </tr>
                </thead>
                <tbody>
                {roles.map(role => (
                    <tr key={role.id}>
                        <td>{role.name}</td>
                        <td>
                            {permissions.map(permission => (
                                <Form.Check
                                    key={permission.id}
                                    type="checkbox"
                                    label={permission.title ? t(permission.title) : permission.name}
                                    checked={role.permissions.some(p => p.id === permission.id)}
                                    onChange={() => handleSyncPermissions(role.id, permission.id)}
                                />
                            ))}
                        </td>
                        <td>
                            <Button
                                variant="danger"
                                onClick={() => handleDeleteRole(role.id)}
                            >
                                Löschen
                            </Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Container>
    );
};

export default Roles;
