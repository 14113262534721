import './bootstrap';
import { createInertiaApp } from '@inertiajs/react'
import { createRoot } from 'react-dom/client'
import {Layout,Pages,PollingProvider} from 'laravel-inertia-backend';
import './bootstrap';
import '../scss/app.scss';
import { LaravelReactI18nProvider } from 'laravel-react-i18n';
import {version} from "./version.js";
import HeaderNavExtraComponent from "./Components/HeaderNavExtraComponent.jsx";
import {Image} from "react-bootstrap";
import icons from './menuIcons.js';

createInertiaApp({
    resolve: name => {
        // 1. Lokale Seiten
        console.log(Pages);
        const localPages = import.meta.glob('./Pages/**/*.jsx', { eager: true });
        // 2. Seiten aus der Paketstruktur dynamisch auflösen
        let page = Pages[name] || localPages[`./Pages/${name}.jsx`];

        console.log(page);
        if (!page) {
            throw new Error(`Seite "${name}" wurde weder lokal noch im laravel-inertia-backend gefunden!`);
        }

        // Einheitliche Behandlung von Modulen und Funktionen
        if (typeof page === 'function') {
            // `page` ist eine direkte Funktion (z. B. aus `Pages`)
            page = { default: page }; // Erzeuge manuell ein Modul-ähnliches Objekt
        }

        // 3. Layout hinzufügen, wenn nicht ausgeschlossen
        page.default.layout =
            name.startsWith('Auth/') || name === 'Welcome'
                ? undefined
                : (page) => (
                    <Layout
                        title="Molotow Ordermail 2"
                        menuIcons={icons}
                        titleElement={<Image src="/images/logo_bg_dark.svg" alt="ordermail 2" style={{ height: '1.5em' }} />}
                        sidebarBottomComponent={<div>Version: {version}</div>}
                        headerNavExtraComponent={<HeaderNavExtraComponent />}
                        children={page}
                    />
                );

        return page;
    },
    setup({ el, App, props }) {
        createRoot(el).render(
            <LaravelReactI18nProvider
                locale={'de'}
                files={import.meta.glob('/resources/lang/*.json')}
            >
                <PollingProvider>
                    <App {...props} />
                </PollingProvider>
            </LaravelReactI18nProvider>
        );
    },
});
