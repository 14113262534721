
import React from "react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import {Button, Container} from "react-bootstrap";

export default function Export(props) {
    const { t } = useLaravelReactI18n();

  const handleClick = () => {
    window.location.href ='export/download';
  }

  return (<Container fluid className={"pt-2"}>
          <div className="mt-1 pt-1 pb-3 d-flex">
              <h1>{t('Export')}</h1>
          </div>
      <Button onClick={handleClick}>Produkte als CSV Exportieren</Button>
  </Container>);
}



