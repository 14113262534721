import {Badge} from "react-bootstrap";
import React from "react";

export default function CurrencyTableCell({ id,className,children }) {
    // Filtere nur Inhalte, die sinnvoll sind (nicht leer, keine Leerzeichen, kein null/undefined)
    const validChildren = React.Children.toArray(children).filter(
        (child) => typeof child === "string" && child.trim() !== "" // prüft auf Strings ohne Leerzeichen
    );

    let content = "";
    if (validChildren.length === 1) {
        const parsedValue = parseFloat(validChildren[0]);

        // Formatierung als Eurobetrag
        if (!isNaN(parsedValue)) {
            content = new Intl.NumberFormat("de-DE", {
                style: "currency",
                currency: "EUR",
            }).format(parsedValue);
        }
    }
      return (
        <td key={id} className={`border px-4 py-2 text-end${className ? " " + className : ""}`}>
            {content}
        </td>

      );
  }
