import React, { useState } from 'react'
import {router} from "@inertiajs/react";
import {Row, Col, Modal, ListGroup, ButtonGroup} from 'react-bootstrap';
import { Icon } from '@mdi/react'
import {mdiCodeJson, mdiEmail, mdiEye, mdiFilePdfBox, mdiRefresh} from '@mdi/js'
import {useLaravelReactI18n} from "laravel-react-i18n";
import { JsonView, allExpanded, darkStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';
import AddressComponent from "../../Components/AddressComponent.jsx";
import ShippingInfo from "../../Components/ShippingInfo.jsx";
import {Button} from "laravel-inertia-backend";

export default function ChildView({order}) {
    if (!order) {
        return null;
    }

    const { t, getLocales } = useLaravelReactI18n();
    const [values, setValues] = useState({
        first_name: order.customer_name,
        last_name: order.last_name,
        email: order.email,
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [dumpModalVisible, setDumpModalVisible] = useState(false);
    const [previewResponse, setPreviewResponse] = useState(null);
    // Zustand für die HTML-Antwort
    const [htmlResponse, setHtmlResponse] = useState('');
    const shippingInfo = order?.shippinginfo ? JSON.parse(order.shippinginfo) : null;

    function handleChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }

    function handlePreview() {
        axios.get(`order/preview`,{ params: { id: order.id }})
            .then(response => {
                setHtmlResponse(response.data);
                setModalVisible(true);
            });
    }

    function handlePdf() {
        window.open(`order/download/`+ order.id,'_blank');
    }

    function handleDumpShow(){
        if (order.request_dump){
            setDumpModalVisible(true);
        }
    }

    function handleRecreate() {

        router.get(`order/recreate/${order.id}`);
    }
    function handleResendEmail() {
        if (window.confirm(t("Are you sure you want to resend order email?"))) {
            router.get(`order/resend/${order.id}`);
        }

    }

    function getConnectionType(id) {
        switch(id) {
            case 1: return 'B2B';
            case 2: return 'B2C alt';
            case 3: return 'B2C';
            default: return '';
        }
    }

    function buildMailToLink() {
        const shop_order_id = order.shop_order_id;
        const connection_type = getConnectionType(order.connection_id);

        const subject = `RE: Molotow ${connection_type} Bestellung ${shop_order_id}`;
        return `mailto:${order.customer_email}?subject=${subject}`;
    }

    return (
        <>
                <Row>
                    <Col>
                        <h5>{t('order details')}</h5>
                        <p><strong>{t('order id')}:</strong> {order.shop_order_id}<br/>
                            <strong>{t('date')}:</strong> {order.order_date}<br/>
                            <strong>Kunde:</strong> {order.customer_name} <a
                                href={buildMailToLink()}>{order.customer_email}</a><br/>
                            <strong>Status:</strong> <span
                                className={order.status ? '' : 'text-danger'}>{order.status ? t('success') : t('error')}</span><br/>
                            <strong>Gesendet:</strong> {order.send ? order.send : 'noch nicht gesendet'}</p>
                    </Col>
                    <Col>
                        <h5>{t('billing address')}</h5>
                        <AddressComponent address={order.billing_address} />
                    </Col>
                    <Col>
                        <h5>{t('shipping address')}</h5>
                        <AddressComponent address={order.shipping_address}/>
                    </Col>
                <Col>
                    <h5>{t('shipping info')}
                    </h5>
                    <ShippingInfo shippingInfo={shippingInfo}/>
                </Col>
                    <Col md={1} className={"text-end"}>
                        <ButtonGroup vertical>
                                {order.request_dump && <Button tooltip={"Rohdaten der Bestellung anzeigen"} tooltipPlacement={"left"} onClick={handleDumpShow}>
                                    <Icon path={mdiCodeJson} size={1}/>
                                </Button>}
                                <Button tooltip={"PDF der Bestellung anzeigen"} tooltipPlacement={"left"} onClick={handlePdf} >
                                    <Icon path={mdiFilePdfBox} size={1}/>
                                </Button>
                                <Button tooltip={"Vorschau der Bestellung anzeigen"} tooltipPlacement={"left"} onClick={handlePreview}>
                                    <Icon path={mdiEye} size={1}/>
                                </Button>
                                <Button tooltip={"Bestellung neu erzeugen"} tooltipPlacement={"left"} onClick={handleRecreate}>
                                    <Icon path={mdiRefresh} size={1}/>
                                </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            {order.comment && <Row>
                <h5>Bestellkommentar</h5>
                <div className={"order-comment"} style={{
                    backgroundColor: "#fffdd9",
                    border: "2px solid #f00",
                    fontWeight: "bold"
                }}>{order.comment}</div>
            </Row>}
            <Row>
                {!order.status && (<>
                    <h5>{t('order errors')}</h5>
                    {order.order_errors && order.order_errors.length > 0 && (

                        <ListGroup variant={"flush"}>
                            {order.order_errors.map((error, index) => (
                                <ListGroup.Item key={index}>
                                    <strong>SKU:</strong> {error.sku}, <strong>Description:</strong> {error.description}
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    )}
                </>)}
            </Row>
            <Modal show={modalVisible} fullscreen={true} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('ordermail preview')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: htmlResponse}}/>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalVisible(false)}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={dumpModalVisible} fullscreen={true} onHide={() => setDumpModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('ordermail request dump')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <JsonView data={order.request_dump} shouldExpandNode={allExpanded} style={darkStyles} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDumpModalVisible(false)}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
</>
    )
}
