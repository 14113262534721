import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useForm } from "@inertiajs/react";

const ChangePassword = () => {
    const { data, setData, post ,errors} = useForm({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    });

    const handleChange = (event) => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        post('/password/change');
    };

    return (
        <div className="container mt-3">
            <h2>Passwort ändern</h2>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="current-password">
                    <Form.Label>Aktuelles Passwort</Form.Label>
                    <Form.Control
                        type="password"
                        name="currentPassword"
                        value={data.currentPassword}
                        onChange={handleChange}
                        required
                    />
                    {errors.email && <div>{errors.email}</div>}
                </Form.Group>
                <Form.Group controlId="new-password">
                    <Form.Label>Neues Passwort</Form.Label>
                    <Form.Control
                        type="password"
                        name="newPassword"
                        value={data.newPassword}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Form.Group controlId="confirm-password">
                    <Form.Label>Bestätige neues Passwort</Form.Label>
                    <Form.Control
                        type="password"
                        name="confirmPassword"
                        value={data.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </Form.Group>
                <Button variant="primary" type="submit">
                    Passwort ändern
                </Button>
            </Form>
        </div>
    );
};

export default ChangePassword;
