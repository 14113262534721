import {mdiChevronDown} from "@mdi/js";
import Icon from "@mdi/react";
import React, {useEffect, useRef, useState} from "react";

// Inlines Styles für Struktur
const closeButtonStyle = {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    fontSize: '24px',
};


const MailView = ({ mail, onClose }) => {
    const { subject, body, from, recipient, received_at } = mail;

    // Ref & State zur Nachverfolgung der Größe
    const mailViewRef = useRef(null);
    const [height, setHeight] = useState(400); // Default-Höhe
    const [maxHeight, setMaxHeight] = useState(window.innerHeight); // Fallback

    useEffect(() => {
        if (mailViewRef.current) {
            const parent = mailViewRef.current.parentElement; // Nächstgelegener Container
            if (parent) {
                const parentRect = parent.getBoundingClientRect(); // Größe des UI-Containers
                setMaxHeight(parentRect.height); // Dynamische Maximalhöhe setzen
            }
        }
    }, []);

    const handleMouseDown = (e) => {
        e.preventDefault();


        const startY = e.clientY; // Anfangsposition der Maus
        const startHeight = mailViewRef.current.offsetHeight;

        const handleMouseMove = (e) => {
            const delta = e.clientY - startY;
            let newHeight = startHeight - delta;

            // Dynamische Maximalhöhe verwenden
            if (newHeight < 100) {
                newHeight = 100; // Mindesthöhe
            } else if (newHeight > maxHeight) {
                newHeight = maxHeight; // Berechnete Maximalhöhe
            }

            setHeight(newHeight);
        };

        const handleMouseUp = () => {
            // Listener entfernen
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };

        // Listener hinzufügen
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    return (
        <div
            className="d-flex flex-column"
            id="mail-view"
            ref={mailViewRef} // Ref binden
            style={{height: `${height}px`, border: "1px solid #ccc"}}
        >
            <div
                className="mail-view-resizer"
                onMouseDown={handleMouseDown} // MouseDown-Handler für Resizing
                style={{
                    height: "3px",
                    background: "rgba(34,34,34,0.6)",
                    cursor: "ns-resize",
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    right: 0,
                }}
            />
            <div className={"mail-view-close"}>
                <button
                    onClick={onClose}
                    style={{background: "none", border: "none", cursor: "pointer"}}
                    aria-label="Close Event"
                >
                    <Icon color={"rgb(110,110,110)"} path={mdiChevronDown} size={1}/>
                </button>
            </div>
            <div className="email-header">
                <h5>{subject}</h5>
                <p>
                    <strong>From:</strong> {from} <br/>
                    <strong>Date:</strong> {received_at}
                </p>
            </div>
            <div className="email-body h-100">
                <div className="email-content">
                    <p dangerouslySetInnerHTML={{__html: body}}></p>
                </div>
            </div>
        </div>
    );
};

export default MailView;
