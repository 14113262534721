import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Die Address-Komponente erwartet ein Prop 'address'
const AddressComponent = ({ address }) => {
  return (
          <p className={'address'}>
            {address.firstname}{' '}
            {address.middlename ? address.middlename + ' ' : ''}
            {address.lastname}
            <br />
            {address.company && (
              <>
                {address.company}
                <br />
              </>
            )}
            {address.street}
            <br />
            {address.street2 && (
              <>
                {address.street2}
                <br />
              </>
            )}
            {address.postcode} {address.city}
            <br />
            {address.country_id}
            <br />
            {address.telephone}
          </p>
  );
};

export default AddressComponent;
